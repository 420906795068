export const msalConfig = {
    auth: {
      clientId: "9f0e97b2-7122-4852-a2cc-d05c8d886a12",
      authority: "https://login.microsoftonline.com/a7f53388-893d-49a4-94df-957f5ced1518", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://swisslogreset.dev.ejgallo.com",
      //https://swisslogreset.dev.ejgallo.com/index.html
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read.All"]
  };
  
 
  export const endPoint = "https://ccziquncp0.execute-api.us-west-2.amazonaws.com/dev/"